
import Mainpage from './components/Mainpage';


function App() {
  return (
        <div>
    
          <Mainpage/>
     
        </div>
     
      
  );
}

export default App;
